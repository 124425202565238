<script setup lang="ts">
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { GaugeChart } from 'echarts/charts'
import {
  TitleComponent,
  GridComponent,
  TooltipComponent,
} from 'echarts/components'
import VChart from 'vue-echarts'

const colorMode = useColorMode()

use([CanvasRenderer, TitleComponent, GaugeChart, TooltipComponent, GridComponent])

const { t } = useLocale()
const chart = ref()

const option = ref<any>({
  series: [
    {
      name: 'gauge 2',
      type: 'gauge',
      min: 1,
      max: 8,
      z: 10,
      startAngle: 210,
      endAngle: -30,
      splitNumber: 7,
      radius: '75%',
      center: ['50%', '50%'],
      axisLine: {
        show: true,
        lineStyle: {
          width: 8,
          color: [
            [0.825, colorMode.value === 'light' ? '#0A0A0A' : '#E5E5E5'],
            [1, colorMode.value === 'light' ? '#A7000C' : '#DF7462'],
          ],
        },
      },
      splitLine: {
        distance: 4,
        length: 12,
        lineStyle: {
          color: 'auto',
          width: 4,
        },
      },
      axisTick: {
        distance: 4,
        length: 6,
        lineStyle: {
          color: 'auto',
          width: 2,
        },
      },
      axisLabel: {
        show: true,
        distance: 20,
        color: '#A3A3A3',
        formatter: (value: number) => {
          return menuList.value[value - 1].name
        },
      },
      pointer: {
        width: 2,
        offsetCenter: [0, '0%'],
        length: '70%',
        itemStyle: {
          color: '#2FC38D',
          shadowColor: '#2FC38DF1',
          shadowBlur: 2,
          shadowOffsetY: 2,
        },
      },
      title: {
        show: false,
        color: '#525252',
        fontSize: 22,
        fontWeight: 800,
        fontFamily: 'Pretendard Variables',
        offsetCenter: [0, '60%'],
      },
      detail: {
        show: false,
      },
      data: [
        {
          name: '',
        },
      ],
    },
    {
      name: 'gauge 3',
      type: 'gauge',
      min: 0,
      max: 8,
      z: 10,
      splitNumber: 8,
      radius: '75%',
      axisLine: {
        lineStyle: {
          width: 8,
          color: [[1, colorMode.value === 'light' ? '#0A0A0A' : '#F5F5F5']],
        },
      },
      splitLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
      pointer: {
        show: false,
      },
      title: {
        show: false,
      },
    },
  ],
})

const menuList = ref([
  { index: 0, name: t('main.menu.skill'), url: '/my/repairSkill' },
  { index: 1, name: t('main.menu.reviewWrite'), url: '/review/motorcycle/write/init' },
  { index: 2, name: t('main.menu.review'), url: '/review/motorcycle' },
  { index: 3, name: t('main.menu.personal'), url: '/meeting/owner' },
  { index: 4, name: t('main.menu.repaire'), url: '/meeting/repair' },
  { index: 5, name: t('main.menu.coaching'), url: '/meeting/coaching' },
  { index: 6, name: t('main.menu.rescue'), url: '/meeting/rescue' },
  { index: 7, name: t('main.menu.pickup'), url: '/meeting/pickup' },
])

const selectMenu = ref('')
const selectMenuUrl = ref('')
const selectMenuTrigger = ref(true)

const handleButtonClick = (menu: { index: number, name: string, url: string }) => {
  selectMenuTrigger.value = false
  option.value.series[0].data[0].value = menu.index + 1
  selectMenuUrl.value = menu.url
  chart.value.setOption(option.value)
}
</script>

<template>
  <div class="w-full h-fit flex flex-col items-center gap-4">
    <WMSelectMenu
      v-model="selectMenu"
      select-class="w-[200px]"
      :options="menuList"
      option-attribute="name"
      size="xl"
      :placeholder="$t('text.menuSelect')"
      :ui="{ width: 'min-w-[200px] w-full' }"
      @update:model-value="handleButtonClick"
    />
    <div class="w-full h-full flex flex-col justify-center items-center">
      <VChart
        ref="chart"
        class="max-w-[400px] max-h-[400px] w-[calc(100dvw-20px)] h-[calc(100dvw-20px)] sm:w-[400px] sm:h-[400px]"
        :option="option"
        autoresize
      />
      <div class="h-14 w-full">
        <AButton
          v-show="!selectMenuTrigger"
          button-block
          button-size="lg"
          button-color="green"
          button-variant="outline"
          :button-text="$t('button.navigate')"
          @click="navigateTo(selectMenuUrl)"
        />
      </div>
    </div>
  </div>
</template>
