<script setup lang="ts">
const user = useSupabaseUser()

const { t } = useLocale()

useHead({
  title: t('pageTitle.main'),
})

// footer높이 고려를 안한 높이영역 h-[calc(100dvh-92px)]
</script>

<template>
  <div class="w-dvw h-[calc(100dvh-100px)] lg:w-[500px] flex flex-col items-center justify-evenly px-8 gap-y-8">
    <LoginButton
      v-if="!user"
      class="mt-20 sm:mt-[140px]"
    />
    <MainController />
    <AFooter />
  </div>
</template>
