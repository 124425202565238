<template>
  <div class="w-full flex flex-col gap-y-1 px-4 mb-4">
    <div class="flex flex-col sm:flex-row gap-x-2">
      <span class="text-xs break-keep">
        상호: 굿노드주식회사
      </span>
      <WMDivider orientation="vertical" />
      <span class="text-xs break-keep">
        대표자: 이승기
      </span>
      <WMDivider orientation="vertical" />
      <span class="text-xs break-keep">
        개인정보관리 책임자: 이승기
      </span>
    </div>
    <div class="text-xs break-keep">
      사업자등록번호: 590-87-03312
      <WMLink
        to="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=5908703312"
        inactive-class="text-xs text-green-500 break-keep hover:text-green-700 transition-colors duration-200 ease-in-out"
        external
        size="xs"
        target="_blank"
      >
        [사업자등록번호 확인]
      </WMLink>
    </div>
    <p class="text-xs break-keep">
      주소: 서울특별시 영등포구 신길로 29, 106동 1204호
    </p>
  </div>
</template>
